import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Navigation from './Navigation';
import MobileNav from './MobileNav';
import config from './config';
import axios from 'axios';
import Cookies from 'js-cookie';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #06060e;
  }
`;

const StyledSettings = styled.div`
  width: 40%;
  margin: 0 auto !important;
  border: 1px solid orange;
  padding: 20px;
  background-color: #06060e;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding: 10px;
  }
`;

const SettingsTitle = styled.h2`
  color: #fff;
  text-align: center;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
`;

const DescriptionText = styled.p`
  color: #fff;
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  transition: .4s;
  border: 2px solid orange;
  border-radius: 34px; // make it fully rounded

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%; // make it a circle
  }

  ${ToggleInput}:checked + & {
    background-color: #6e4709;
  }

  ${ToggleInput}:checked + &:before {
    transform: translateX(24px);
  }

  ${ToggleInput}:focus + & {
    box-shadow: 0 0 1px #2196F3;
  }

  ${ToggleInput}:active + & {
    background-color: #000;
  }
`;

const HelpMature = () => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <Navigation />
      <StyledSettings>
        <SettingsTitle>What is considered mature?</SettingsTitle>
        <DescriptionText>YipYap has a duty to ensure we are both following local laws, and also protecting users from unwillingly seeing adult content- both minors, and adults who wish to not see this content. YipYap likes to take a lax, but protective approach to adult content, and what must be marked as mature, including:</DescriptionText>
        <DescriptionText>- Nudity or pornography of any kind</DescriptionText>
        <DescriptionText>- Heavy gore or violence</DescriptionText>
        <br />
        <DescriptionText>Failing to mark either of the above topics as mature may result in Yaps being marked as mature, taken down, or potentially even getting your YipYap account suspended.</DescriptionText>
        <br />
        <DescriptionText>While we do have rules on what must be marked as mature, we do not have rules on what cannot be marked as mature. This means that if you’d like to mark any Yaps as mature (including those that do not feature any adult content at all), you may do so. Any Yaps that are not marked as mature will still be fully visible by those who aren’t able to see mature content, and your Yaps that are marked as mature will not affect your Yaps that are not.</DescriptionText>
        <br />
        <DescriptionText>Following off from that, this also means that if you’re not sure if a piece of content is or should be considered mature, you are free to (and encouraged to) mark it as mature regardless.</DescriptionText>
        <br />
        <DescriptionText>Additionally, we do not consider any types of clothing to inherently be mature, and it is up to the poster if they wish to mark it as such. However, we do suggest that users are aware that some may consider certain content mature, and to be mindful of others. If there’s no particular reason you may want a minor or mature-filtering adult to be able to see a specific piece of content, it may be wise to mark it as mature.</DescriptionText>
        <br />
        <DescriptionText>And as the most important, final point: have fun, and yap loud!</DescriptionText>
      </StyledSettings>
      <MobileNav />
    </React.Fragment>
  );
}

export default HelpMature;
