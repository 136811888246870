const dev = {
    backend: "http://localhost:5001",
    frontend: "http://localhost:3000",
};

const prod = {
    backend: "https://api.yipyap.io",
    frontend: "https://yipyap.io",
};

const debugMode = false; //remember to also change in index.js

const config = debugMode ? dev : prod;

export default config;
