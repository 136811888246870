import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import config from './config';
import { useEffect, useState } from 'react';
import PostItem from './PostItem';

export const PostImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow-clip-margin: unset;
  position: absolute;
  top: 0;
  left: 0;
`;

const Divider = styled.div`
  height: 1px;
  background-color: #332f29;
  margin: 0px auto;

  @media (min-width: 768px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

// Vertical line between profile pictures in the thread
const ReplyLineContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const VerticalLine = styled.div`
  width: 2px;
  background-color: grey;
  position: absolute;
  top: 50%; // Adjust this value based on the height of the profile picture
  bottom: 50%; // Adjust this value based on the height of the profile picture
  left: 50%; // Center the line between profile pictures
  transform: translateY(-50%);
`;

const ProfilePictureWrapper = styled.div`
  width: 90%;
  position: relative;
  z-index: 1;
  margin-bottom: 20px; // Adjust this value based on the spacing between profile pictures
`;

function PostList({ posts, users }) {
  const [likeStatus, setLikeStatus] = useState({});
  const [listExpand, setListExpand] = useState({});

  useEffect(() => {
    const status = {};
    const expandedStatus = {};

    posts.forEach(p => {
      status[p.PostID] = {
        liked: p.iLiked,
        likeCount: p.LikeCount,
      };
      expandedStatus[p.PostID] = false;
    });
    setLikeStatus(status);
    setListExpand(expandedStatus);
  }, [posts]);

  const handleLike = async (postId) => {
    setLikeStatus(prevState => {
      const newState = { ...prevState };
      newState[postId].liked = !newState[postId].liked;
      newState[postId].likeCount += newState[postId].liked ? 1 : -1;
      return newState;
    });

    await likePost(postId);
  };

  const handleExpand = (postId) => {
    setListExpand(prevState => ({
      ...prevState,
      [postId]: !prevState[postId],
    }));
  };

  async function likePost(postId) {
    try {
      const response = await axios({
        method: 'post',
        url: config.backend + '/dolike',
        data: {
          postId: postId,
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer ' + Cookies.get('token'),
        },
      });
    } catch (error) {
      console.error(error); // Log any errors to the console
    }
  }

  const handlePostClick = (username, postId) => {
    posts = [];
    window.location.assign(`/${username}/${postId}`);
  };

  const renderPostThread = (post, users, index, posts) => {
    const isReply = post.ReplyTo !== null;
    const nextPostIsReply = index + 1 < posts.length && posts[index + 1].ReplyTo === post.PostID;

    return (
      <React.Fragment key={post.PostID}>
        <ReplyLineContainer>
          <ProfilePictureWrapper>
            <PostItem
              post={post}
              user={users[post.PosterId]} // Ensure the user exists before rendering
              liked={likeStatus[post.PostID]?.liked}
              likeCount={likeStatus[post.PostID]?.likeCount}
              handleLikeToggle={() => handleLike(post.PostID)}
              expanded={!!listExpand[post.PostID]}
              handleExpand={() => handleExpand(post.PostID)}
            />
            {isReply && <VerticalLine />}
          </ProfilePictureWrapper>
        </ReplyLineContainer>
        {nextPostIsReply && <VerticalLine />}
        <Divider />
      </React.Fragment>
    );
  };

  return (
    <>
      {posts.map((post, index) => {
        if (!users[post.PosterId]) {
          return null;
        }

        return renderPostThread(post, users, index, posts);
      })}
    </>
  );
}

export default PostList;
