import React, { useState } from 'react';
import styled from 'styled-components';
import { useSwipeable } from 'react-swipeable';

const FullscreenImageViewer = ({ images, currentIndex, onClose }) => {
    const [index, setIndex] = useState(currentIndex);
  
    const handlePrev = (e) => {
      e.stopPropagation();  // Prevent event from propagating to FullscreenBackground
      if (index > 0) setIndex(index - 1);
    };
  
    const handleNext = (e) => {
      e.stopPropagation();  // Prevent event from propagating to FullscreenBackground
      if (index < images.length - 1) setIndex(index + 1);
    };
  
    const handleImageClick = (e) => {
      e.stopPropagation();  // Prevent event from propagating to FullscreenBackground
    };
  
    const handleClose = (e) => {
      e.stopPropagation();
      onClose();
    };
  
    return (
      <FullscreenBackground onClick={handleClose}>
        {index > 0 && <LeftArrow onClick={handlePrev} />}
        <FullscreenImage src={images[index]} alt={`Image ${index + 1}`} onClick={handleImageClick} />
        {index < images.length - 1 && <RightArrow onClick={handleNext} />}
      </FullscreenBackground>
    );
  };

const FullscreenBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const FullscreenImage = styled.img`
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
`;

const Arrow = styled.div`
  cursor: pointer;
  background-color: transparent;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  position: absolute;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const LeftArrow = styled(Arrow)`
  background-image: url('https://api.yipyap.io/resource/arrow-left.png');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  left: 20px; /* Adjust for desired spacing from edge */
  top: 50%; /* Center vertically */
  transform: translateY(-50%);
`;

const RightArrow = styled(Arrow)`
  background-image: url('https://api.yipyap.io/resource/arrow-right.png');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  right: 20px; /* Adjust for desired spacing from edge */
  top: 50%; /* Center vertically */
  transform: translateY(-50%);
`;

export default FullscreenImageViewer;
