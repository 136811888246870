import React, { useState } from 'react';
import styled from 'styled-components';

const RightNav = styled.nav`
  width: 20%;
  height: 25vh;
  position: fixed;
  top: 10%; // start slightly below the top of the screen
  right: 0; // position the navigation on the right side of the screen
  color: #fff;
  border-radius: 10px;
  padding: 10px 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
`;

const FilterButton = styled.button`
  background-color: ${({ selected }) => (selected ? 'orange' : 'transparent')};
  color: #fff;
  border: 1px solid orange;
  border-radius: 50px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  width: 100%;

  &:hover {
    background-color: ${({ selected }) => (selected ? 'orange' : 'rgba(255, 255, 255, 0.1)')};
  }
`;

const SortingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #06060e;
  padding: 10px;
  flex-grow: 1;

  @media (max-width: 768px) {
    display: ${({ visible }) => (visible ? 'flex' : 'none')};
    position: absolute;
    right: 0;
    top: 50px;
    width: 80%;
    background-color: #06060e;
    padding: 20px;
    border: 1px solid #332f29;
    z-index: 1000;
  }
`;

const FilterYap = ({ setPhotosOnly, photosOnly, setNoPhotos, noPhotos, setVideosOnly, videosOnly, setNoVideos, noVideos, setHowlsOnly, howlsOnly, setNoHowls, noHowls }) => {
  return (
    <RightNav>
      <SortingContainer>
        <h3 style={{ color: "white" }}>Filter Yaps By</h3>
        <ButtonGroup>
          <FilterButton
            selected={photosOnly}
            onClick={() => {
              setPhotosOnly(!photosOnly);
              if (!photosOnly) setNoPhotos(false);
            }}
          >
            Photos Only
          </FilterButton>
          
          <FilterButton
            selected={noPhotos}
            onClick={() => {
              setNoPhotos(!noPhotos);
              if (!noPhotos) setPhotosOnly(false);
            }}
          >
            No Photos
          </FilterButton>

          <FilterButton
            selected={videosOnly}
            onClick={() => {
              setVideosOnly(!videosOnly);
              if (!videosOnly) setNoVideos(false);
            }}
          >
            Videos Only
          </FilterButton>
          
          <FilterButton
            selected={noVideos}
            onClick={() => {
              setNoVideos(!noVideos);
              if (!noVideos) setVideosOnly(false);
            }}
          >
            No Videos
          </FilterButton>

          <FilterButton
            selected={howlsOnly}
            onClick={() => {
              setHowlsOnly(!howlsOnly);
              if (!howlsOnly) setNoHowls(false);
            }}
          >
            Howls Only
          </FilterButton>
          
          <FilterButton
            selected={noHowls}
            onClick={() => {
              setNoHowls(!noHowls);
              if (!noHowls) setHowlsOnly(false);
            }}
          >
            No Howls
          </FilterButton>
        </ButtonGroup>
      </SortingContainer>
    </RightNav>
  );
};

export default FilterYap;
