import React, { useState, createContext, useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import config from './components/config';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #06060e;
    color: #fff;
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
  }
`;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #06060e;
  padding: 20px;
`;

const AuthForm = styled.div`
  background-color: #111;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 100%;
`;

const FormTitle = styled.h1`
  text-align: center;
  margin-bottom: 30px;
  color: #ffba3b;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: lightgray;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  background-color: #333;
  border: 1px solid #666;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  outline: none;

  &:focus {
    border-color: orange;
  }
`;

const AuthButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: orange;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ffba3b;
  }
`;

const SwitchModeLink = styled.span`
  display: block;
  text-align: center;
  margin-top: 20px;
  color: #ffba3b;
  cursor: pointer;
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
`;

const LoginPage = () => {
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');


  const handleLogin = async () => {
    // Send a POST request to the login endpoint
    axios.post(config.backend + '/login', {
      Username: username,
      Password: password
    })
    .then((response) => {
      // The request was successful
      const { token } = response.data;
  
      // Call the setToken function from our AuthContext to save the token
      Cookies.set('token', token);
      Cookies.set('username', username);
        window.location.replace(config.frontend);
    })
    .catch((error) => {
      // The request failed
      alert(error);
    });
  };


  const handleRegister = async () => {
    const dataToSend = {
        username: username,
        password: password,
        email: ''
      };
    
      console.log("Sending data: ", dataToSend);
    
      // Send a POST request to the registration endpoint
      axios.post(config.backend + '/register', {
        username: username,
        password: password,
        email: ''
      })
      .then((response) => {
        // The request was successful
        // console.log(response.data);
          window.location.replace(config.frontend);
      })
      .catch((error) => {
        // The request failed
        alert(error);
      });
  };

  return (
    <React.Fragment>
      <GlobalStyle />
      <PageContainer>
        <AuthForm>
          <FormTitle>{isLoginMode ? 'Login' : 'Create Account'}</FormTitle>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <FormGroup>
            <Label htmlFor="username">Username</Label>
            <Input
              id="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter your username"
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
            />
          </FormGroup>
          <AuthButton onClick={isLoginMode ? handleLogin : handleRegister}>
            {isLoginMode ? 'Login' : 'Create Account'}
          </AuthButton>
          <SwitchModeLink onClick={() => setIsLoginMode(!isLoginMode)}>
            {isLoginMode ? "Don't have an account? Create one" : 'Already have an account? Login'}
          </SwitchModeLink>
        </AuthForm>
      </PageContainer>
    </React.Fragment>
  );
};

export default LoginPage;