import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import PostList from './PostList';
import config from './config';
import PostBox from './PostBox';
import SearchBar from './SearchBar';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #06060e;
  }
`;

const StyledFeed = styled.div`
  width: 40%;
  margin: 0 auto !important;
  padding: 20px;
  background-color: #06060e;
  border-top: 1px solid #332f29;

  @media (min-width: 768px) {
    border-left: 1px solid #332f29;
    border-right: 1px solid #332f29;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

const Feed = () => {
  const [data, setData] = useState({posts: [], users: {}});
  const [AllowNSFW, setAllowNSFW] = useState(false);
  
  useEffect(() => {
    const token = Cookies.get('token');
    let myID = -1;
    if (token) {
      axios.get(config.backend + '/valid', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(res => {
        try {
          myID = res.data.userId;
          setAllowNSFW(res.data.settings.allowNSFW);
          axios.get('/api/v1/posts?myID=' + myID + '&page=0&onlyFollow=true')
          .then(res => {
            console.log("Data: " + JSON.stringify(res.data));
            setData(res.data);
          })
          .catch(err => console.log(err));
        } catch (e) { }
      })
      .catch(() => { });
    }
  }, []);

  return (
    <React.Fragment>
      <GlobalStyle />
      <StyledFeed>
        <PostBox AllowNSFW={AllowNSFW} isQuote={false} />
        <PostList posts={data.posts} users={data.users} />
      </StyledFeed>
      <SearchBar />
    </React.Fragment>
  );
};

export default Feed;