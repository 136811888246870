import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import config from './config';
import Cookies from 'js-cookie';
import MobileNav from './MobileNav';
import Navigation from './Navigation';

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #06060e;
  padding: 0;
`;

const Notification = styled.div`
  position: relative;
  background-color: #06060e;
  display: flex;
  align-items: flex-start; // Align items to the top
  padding: 10px;
  color: #FFF;
  border-left: 1px solid orange;
  border-right: 1px solid orange;

  &:first-child {
    border-top: 1px solid orange;
  }

  &:last-child {
    border-bottom: 1px solid orange;
  }

  &:not(:first-child)::before {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    border-top: 1px solid orange;
  }

  @media (min-width: 768px) {
    width:40%;
    margin: 0 auto !important;
    padding: 20px;
    background-color: ##06060e;
  }
`;

const NotificationHeader = styled.div`
  display: flex;
`;

const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px; // Adjust spacing between image and text content
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const NotificationTitle = styled.h3`
  margin: 0;
`;


const ParentContainer = styled.div`
  background-color: #06060e; // Set the background to black
  min-height: 100vh; // Ensure it takes at least the full height of the viewport
`;

const CaughtUpMessage = styled.div`
  color: #aaa;
  text-align: center; // Center the text horizontally
  padding: 20px; // Add some padding around the text
  font-size: 18px; // Adjust the font size as needed
`;

const MessageText = styled.p`
  color: #dde;
`;

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    
    useEffect(() => {
      const fetchNotifications = async () => {
        try {
            axios({
                method: 'get',
                  url: config.backend + '/notifications',
                headers: {
                  'Authorization': `Bearer ${Cookies.get('token')}`
                }
              })
              .then(res => {
                try
                {
                    setNotifications(res.data);
                }
                catch(e) { }
                })
          
        } catch (error) {
          console.error('Error fetching notifications:', error);
        }
      };
  
      fetchNotifications();
    }, []);
  
    return (
      <ParentContainer>
        <Navigation />
        <NotificationContainer>
          {notifications.map((notification, index) => (
            <Notification key={notification.id}>
              <NotificationHeader>
                <ProfileImage src={notification.userPFP} alt={notification.username} />
                <NotificationContent>
                  <NotificationTitle>{notification.title}</NotificationTitle>
                  <MessageText>{notification.message}</MessageText>
                </NotificationContent>
              </NotificationHeader>
            </Notification>
          ))}
        </NotificationContainer>
        <CaughtUpMessage>You're all caught up!</CaughtUpMessage>
        <MobileNav />
      </ParentContainer>
    );
  }
  
  export default Notifications;