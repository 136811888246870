import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const StyledSidebar = styled.aside`
  width: 20%;
  background: #333; // Dark grey background
  color: #fff; // White text
`;

const User = styled.div`
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 10px;
`;

const Sidebar = () => {
  const [users, setUsers] = useState([]);

//   useEffect(() => {
//     // Replace with your actual API endpoint
//     axios.get('https://api.yourwebsite.com/users')
//       .then(res => {
//         setUsers(res.data);
//       })
//       .catch(err => console.log(err));
//   }, []);

  return (
    <StyledSidebar>
      <h2>Recommended Users</h2>
      {users.map(user => (
        <User key={user.id}>
          <h3>{user.username}</h3>
        </User>
      ))}
    </StyledSidebar>
  );
};

export default Sidebar;
