import React, { useEffect, useState } from 'react';
import { useRef } from 'react'; 
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import PostList from './PostList';
import config from './config';


const PostButton = styled.button`
  padding: 10px 20px;
  background-color: orange;
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.1s ease-in-out;
  
  &:hover {
    background-color: #ffba3b;
  }

  display: inline-block;
  vertical-align: middle;
`;

const FileInput = styled.input`
  display: none;
`;

const PostContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: ${({ isQuote }) => (isQuote ? 'fixed' : 'relative')};
    top: ${({ isQuote }) => (isQuote ? '50%' : 'initial')};
    left: ${({ isQuote }) => (isQuote ? '50%' : 'initial')};
    transform: ${({ isQuote }) => (isQuote ? 'translate(-50%, -50%)' : 'none')};
    width: ${({ isQuote }) => (isQuote ? '80%' : '100%')};
    z-index: ${({ isQuote }) => (isQuote ? '1001' : 'initial')};
    background-color: ${({ isQuote }) => (isQuote ? '#111' : 'transparent')};
    padding: ${({ isQuote }) => (isQuote ? '20px' : '0')};
    border-radius: ${({ isQuote }) => (isQuote ? '10px' : '0')};
`;

const TextBoxContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

const TextBox = styled.textarea`
  width: 100%;
  height: 150px;
  margin-bottom: 10px;
  background-color: #111;
  border: none;
  outline: none;
  resize: none;
  border-radius: 5px;
  color: white;
  font-size: 18px;

  &::placeholder {
    color: lightgrey;
  }
`;

const UploadButton = styled.button`
  position: absolute;
  right: 10px;
  bottom: 10px; // Place it in the lower-right corner
  cursor: pointer;
  background-color: orange;
  border: none;
  font-size: 1.5em;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 20%; // or 50% for a perfect circle
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
`;

const ImagePreview = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  overflow-clip-margin: unset;
  margin: 10px;
  cursor: pointer;
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

const ToggleLabel = styled.label`
  margin-right: 5px;
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  transition: .4s;
  border: 2px solid orange;
  border-radius: 34px; // make it fully rounded

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%; // make it a circle
  }

  ${ToggleInput}:checked + & {
    background-color: #6e4709;
  }

  ${ToggleInput}:checked + &:before {
    transform: translateX(24px);
  }

  ${ToggleInput}:focus + & {
    box-shadow: 0 0 1px #2196F3;
  }

  ${ToggleInput}:active + & {
    background-color: #000;
  }
`;

const VideoPreview = styled.video`
  width: 100px;
  height: 100px;
  object-fit: cover;
  overflow-clip-margin: unset;
  margin: 10px;
  cursor: pointer;
`;

const PostBox = ({ AllowNSFW, Quote, isQuote }) => {
    const [newPost, setNewPost] = useState("");
    const [MyUserID, setMyUserID] = useState(-1);
    const [ThisPostNSFW, setThisPostNSFW] = useState(false);
    const [isPosting, setIsPosting] = useState(false);
    const [tags, setTags] = useState([]);
    const [tagInput, setTagInput] = useState("");
  
    const handlePost = () => {
      const token = Cookies.get('token');
      if (!token) {
        window.location.replace('/login');
      } else {
        console.log(newPost);
      }
    };

    const makePost = () => {
        try {
            const token = Cookies.get('token');
            const formData = new FormData();
            let text = newPost;
            if (newPost === "" && selectedImages.length === 0) {
              alert("Post must have content");
              return;
            }
      
            formData.append('Text', text);
        
            setIsPosting(true);
            if (selectedImages.length === 0) {
              formData.append('Images', [null]);
            } else {
              selectedImages.forEach((image, index) => {
                formData.append('Files', image.file);
              });
            }

            console.log("iq: " + isQuote);
      
            if (isQuote) {
              formData.append('Quoting', Quote);
            }
        
            formData.append('NSFW', ThisPostNSFW);
            formData.append('Tags', JSON.stringify(tags));
        
            axios({
              method: 'post',
              url: '/api/v1/send_post',
              data: formData,
              headers: {
                'Authorization': `Bearer ${token}`
              }
            })
            .then(res => {
              // window.location.replace(config.frontend);
            })
            .catch(err => console.log(err))
            .finally(() => {
              setIsPosting(false);
            });
        } catch(error) {
            alert("Error sending Yap: " + error);
        }
    };
  
    const fileInput = useRef();
    const handleUploadClick = () => {
      fileInput.current.click();
    };
  
    const [selectedImages, setSelectedImages] = useState([]);
    const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const fileSizeLimit = 20 * 1024 * 1024;
        if (file.size <= fileSizeLimit) {
          const reader = new FileReader();
          reader.onloadend = () => {
            const fileData = { data: reader.result, file, type: file.type };
            setSelectedImages(prev => [...prev, fileData]);
          };
          reader.readAsDataURL(file);
        } else {
          alert(`File size must be less than ${fileSizeLimit / 1024 / 1024}MB.`);
        }
      }
    };
  
    const handleImageClick = (index) => {
      setSelectedImages(prevImages => prevImages.filter((img, i) => i !== index));
    };
  
    const ToggleContainer = styled.div`
      display: ${AllowNSFW ? "flex" : "none"};
      align-items: center;
      justify-content: center;
      color: #fff;
      max-height: 35px;
    `;
  
    const ToggleSwitchComponent = ({initialState}) => {
      const handleToggle = () => {
        setThisPostNSFW(!ThisPostNSFW);
      };
  
      return (
        <ToggleContainer>
          <ToggleLabel htmlFor="seeContent">Mature</ToggleLabel>
          <ToggleSwitch>
            <ToggleInput id="allowNSFW" type="checkbox" checked={ThisPostNSFW} onChange={handleToggle} />
            <ToggleSlider />
          </ToggleSwitch>
        </ToggleContainer>
      );
    };


    return (
        <>
            <FileInput 
            type="file" 
            accept=".png, .mp4, .mov" 
            onChange={handleFileChange} 
            ref={fileInput}
            />
            <PostContainer isQuote={isQuote}>
            <TextBoxContainer>
                <TextBox 
                value={newPost} 
                onChange={e => setNewPost(e.target.value)}
                placeholder="YipYap is currently in beta :3"
                onFocus={handlePost}
                />
                <UploadButton onClick={handleUploadClick}>+</UploadButton>
            </TextBoxContainer>

            <FlexRow>
                <ToggleSwitchComponent initialState={false} />
                <PostButton onClick={makePost} disabled={isPosting}>Post</PostButton>
            </FlexRow>

            {selectedImages.map((file, index) => (
                file.type.startsWith('image/') ? (
                <ImagePreview src={file.data} alt="" onClick={() => handleImageClick(index)} />
                ) : (
                <VideoPreview
                    src={file.data}
                    onClick={() => handleImageClick(index)}
                    controls
                />
                )
            ))}
            </PostContainer>
        </>
    );
};

export default PostBox;