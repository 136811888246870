import React from 'react';
import styled from 'styled-components';
import Navigation from './components/Navigation';
import MobileNav from './components/MobileNav';
import Feed from './components/Feed';
import Sidebar from './components/Sidebar';
import UserProfile from './components/UserProfile';
import SearchBar from './components/SearchBar';

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px;

  @media (max-width: 768px) {
    margin: 0px;
  }
`;

const App = () => {
  return (
    <Layout>
      <Navigation />
      <Feed />
      <MobileNav />
      {/* <Sidebar /> */}
    </Layout>
  );
};

export default App;
