import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Navigation from './Navigation';
import MobileNav from './MobileNav';
import config from './config';
import axios from 'axios';
import Cookies from 'js-cookie';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #06060e;
  }
`;

const StyledSettings = styled.div`
  width: 40%;
  margin: 0 auto !important;
  border: 1px solid orange;
  padding: 20px;
  background-color: #06060e;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding: 10px;
  }
`;

const SettingsTitle = styled.h2`
  color: #fff;
  text-align: center;
`;

const TypeTitle = styled.h3`
  color: #fff;
  text-align: center;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
`;

const DescriptionText = styled.p`
  color: #fff;
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  transition: .4s;
  border: 2px solid orange;
  border-radius: 34px; // make it fully rounded

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%; // make it a circle
  }

  ${ToggleInput}:checked + & {
    background-color: #6e4709;
  }

  ${ToggleInput}:checked + &:before {
    transform: translateX(24px);
  }

  ${ToggleInput}:focus + & {
    box-shadow: 0 0 1px #2196F3;
  }

  ${ToggleInput}:active + & {
    background-color: #000;
  }
`;

const Link = styled.a`
  color: orange;

  &:visited {
  color: orange;
  }
`;

const MuteWordsButton = styled.button`
  background-color: orange;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 1em;
`;

const MuteWordsModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background-color: #06060e;
  border: 1px solid #332f29;
  border-radius: 5px;
  z-index: 1000;
  padding: 20px;
  color: white;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const MutedWordsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
`;

const MutedWordItem = styled.li`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const RemoveButton = styled.button`
  background-color: red;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
`;

const DateInput = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid orange;
  background-color: #111;
  color: white;
  font-size: 1em;
`;

const Settings = () => {
  const [showContent, setShowContent] = useState(false);
  const [mutedWords, setMutedWords] = useState([]);
  const [showMuteModal, setShowMuteModal] = useState(false);
  const [newWord, setNewWord] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [isAdult, setIsAdult] = useState(false);
  const [Settings, setSettings] = useState
  ({
    AllowNSFW: false,
    mutedWords: [],
    birthDate: '',
  });

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      axios.get(config.backend + '/valid', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })      
      .catch(() =>
      {
        
      })
      .then(res => {
       try
       {
        // alert(JSON.stringify(res.data));
        setSettings(res.data.settings);
        setShowContent(res.data.settings.allowNSFW);

        if (res.data.settings.birthDate) {
         setBirthDate(res.data.settings.birthDate);
         checkAge(res.data.settings.birthDate);
        }
       }
       catch(e) { }
      });
    }
  }, []);

  const handleBirthDateChange = (e) => {
    const selectedDate = e.target.value;
    setBirthDate(selectedDate);
    checkAge(selectedDate);

    const token = Cookies.get('token');
    const formData = new FormData();
    formData.append('birthDate', selectedDate);

    axios({
      method: 'post',
      url: config.backend + '/setsettings',
      data: formData,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  };

  const checkAge = (date) => {
    const birth = new Date(date);
    const ageDifMs = Date.now() - birth.getTime();
    const ageDate = new Date(ageDifMs);
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    setIsAdult(age >= 18);
  };

  const handleToggle = () => {
    setShowContent(prev => !prev);

    setSettings({AllowNSFW: showContent});

    const formData = new FormData();
    formData.append('AllowNSFW', !showContent);

    // console.log("NSFW? " + !showContent);

    const token = Cookies.get('token');
    axios({
        method: 'post',
          url: config.backend + '/setsettings',
        data: formData,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  };
  
  const handleAddWord = () => {
    if (newWord && !mutedWords.includes(newWord)) {
      setMutedWords([...mutedWords, newWord]);
      setNewWord('');
      setSettings({mutedWords: mutedWords});
    }

    // todo: send request to server to update
  };

  const handleRemoveWord = (word) => {
    setMutedWords(mutedWords.filter((w) => w !== word));
    // todo: request here too
  };

  return (
    <React.Fragment>
      <GlobalStyle />
      <Navigation />
      <StyledSettings>
        <SettingsTitle>Settings</SettingsTitle>

        <TypeTitle>Content</TypeTitle>

        <MuteWordsButton onClick={() => setShowMuteModal(true)}>
          Mute Words
        </MuteWordsButton>

        {showMuteModal && (
          <>
            <Overlay onClick={() => setShowMuteModal(false)} />
            <MuteWordsModal>
              <h3>Muted Words</h3>
              <input
                type="text"
                value={newWord}
                onChange={(e) => setNewWord(e.target.value)}
                placeholder="Enter a word, tag, or phrase"
              />
              <button onClick={handleAddWord}>Add</button>
              <MutedWordsList>
                {mutedWords.map((word, index) => (
                  <MutedWordItem key={index}>
                    {word}
                    <RemoveButton onClick={() => handleRemoveWord(word)}>X</RemoveButton>
                  </MutedWordItem>
                ))}
              </MutedWordsList>
            </MuteWordsModal>
          </>
        )}

        <TypeTitle>Privacy</TypeTitle>
        {/* Private Account */}
        {/* Blocked Accounts */}


        <TypeTitle>User</TypeTitle>

        <ToggleContainer>
          <label htmlFor="birthDate">Birth Date</label>
          <DateInput
            id="birthDate"
            type="date"
            value={birthDate}
            onChange={handleBirthDateChange}
          />
        </ToggleContainer>
        {isAdult && (
          <>
            <ToggleContainer>
              <label htmlFor="seeContent">See Mature Content</label>
              <ToggleSwitch>
                <ToggleInput id="seeContent" type="checkbox" checked={showContent} onChange={handleToggle} />
                <ToggleSlider />
              </ToggleSwitch>
            </ToggleContainer>
            <DescriptionText>
              Enabling this allows you to view and post mature content. <Link href="https://yipyap.io/mature">What is considered mature?</Link>
            </DescriptionText>
          </>
        )}

      </StyledSettings>
      <MobileNav />
    </React.Fragment>
  );
}

export default Settings;
