import { useEffect, useState, useRef } from 'react';
import useIntersectionObserver from './useIntersectionObserver';

const VideoComponent = ({ src }) => {
  const videoRef = useRef(null);
  const [ref, isVisible] = useIntersectionObserver({
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });

  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [showControls, setShowControls] = useState(true);
  const isMobile = window.innerWidth <= 768;
  let animationFrameId = useRef(null);
  let hideControlsTimeout = useRef(null);

  const [isTouchMoving, setIsTouchMoving] = useState(false);

  const [isScrubbing, setIsScrubbing] = useState(false);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        cancelAnimationFrame(animationFrameId.current);
      } else {
        videoRef.current.play();
        updateProgressBar();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleProgressChange = (event) => {
    const newTime = (event.target.value / 100) * duration;
    videoRef.current.currentTime = newTime;
    setCurrentTime(newTime);
    setProgress((newTime / duration) * 100);
  };

  const updateProgressBar = () => {
    if (videoRef.current && !isScrubbing) {
      setCurrentTime(videoRef.current.currentTime);
      setProgress((videoRef.current.currentTime / videoRef.current.duration) * 100);
    }

    animationFrameId.current = requestAnimationFrame(updateProgressBar);
  };


  const handleScrubStart = () => {
    setIsScrubbing(true);
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
      cancelAnimationFrame(animationFrameId.current);
    }
  };

  const handleScrubEnd = () => {
    setIsScrubbing(false);
    if (isVisible) {
      videoRef.current.play();
      setIsPlaying(true);
      updateProgressBar();
    }
  };

  const handleMobileFullscreen = () => {
    if (isMobile && videoRef.current) {
      if (!isTouchMoving) {
        if (videoRef.current.requestFullscreen) {
          videoRef.current.requestFullscreen();
        } else if (videoRef.current.webkitEnterFullscreen) {
          videoRef.current.webkitEnterFullscreen();
        }

        // Ensure video keeps playing after fullscreen
        if (!isPlaying) {
          videoRef.current.play();
          setIsPlaying(true);
        }
      }
    }
  };

  // Show controls on hover and auto-hide after 1 second
  const showControlsAndHideLater = () => {
    setShowControls(true);
    clearTimeout(hideControlsTimeout.current);
    hideControlsTimeout.current = setTimeout(() => {
      setShowControls(false);
    }, 1000); // Hide controls after 1 second
  };

  // Detect touch start and reset touch move detection
  const handleTouchStart = () => {
    setIsTouchMoving(false); // Reset on touch start
  };

  // Detect touch move (user is scrolling)
  const handleTouchMove = () => {
    setIsTouchMoving(true); // User is scrolling
  };

  // Detect touch end and trigger fullscreen if there was no scrolling
  const handleTouchEnd = (event) => {
    event.stopPropagation(); // Prevent triggering other events
    handleMobileFullscreen();
  };

  // Show controls on video load and auto-hide after 1 second
  useEffect(() => {
    showControlsAndHideLater();
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      if (isVisible) {
        videoRef.current.play();
        setIsPlaying(true);
        updateProgressBar(); // Start updating the progress bar when visible
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
        cancelAnimationFrame(animationFrameId.current); // Stop updating when not visible
      }

      videoRef.current.addEventListener('loadedmetadata', () => {
        setDuration(videoRef.current.duration);
      });

      return () => {
        cancelAnimationFrame(animationFrameId.current); // Cleanup animation frame on unmount
      };
    }
  }, [isVisible]);

  return (
    <div
      ref={ref}
      style={{ position: 'relative', width: '100%' }}
      onMouseMove={showControlsAndHideLater} // Show controls on mouse move (desktop)
      onTouchMove={showControlsAndHideLater} // Show controls on touch move (mobile)
    >
      <div
        onClick={togglePlayPause} // Trigger play/pause only on desktop or non-fullscreen taps
        style={{ position: 'relative' }}
        onTouchStart={handleTouchStart} // Detect touch start
        onTouchMove={handleTouchMove} // Detect touch move (scrolling)
        onTouchEnd={handleTouchEnd} // Handle touch end and fullscreen logic
      >
        <video
          ref={videoRef}
          width="100%"
          loop
          muted
          playsInline
          controls={false} // Hide default controls
          style={{ objectFit: 'cover', display: 'block', width: '100%', borderRadius: '10px' }}
        >
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Custom Controls Overlay */}
        <div
          style={{
            position: 'absolute',
            bottom: '10px',
            left: '0',
            right: '0',
            padding: '0 10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between', // Adjusted for spacing
            opacity: showControls ? 1 : 0, // Control visibility
            transition: 'opacity 0.5s ease', // Smooth fade-out
            pointerEvents: showControls ? 'auto' : 'none', // Allow interaction only when visible
          }}
        >
          <input
            type="range"
            value={progress}
            min="0"
            max="100"
            onChange={handleProgressChange}
            onMouseDown={handleScrubStart} // Start scrubbing on mouse down
            onMouseUp={handleScrubEnd} // End scrubbing on mouse up
            onTouchStart={handleScrubStart} // Start scrubbing on touch start
            onTouchEnd={handleScrubEnd} // End scrubbing on touch end
            style={{
              width: '85%', // Adjusted width to give space for the time
              appearance: 'none',
              background: '#ff5733',
              height: '5px',
              cursor: 'pointer',
              pointerEvents: 'auto', // Allow interaction with the progress bar
            }}
          />
          <div
            style={{
              color: '#fff',
              fontSize: '0.8em',
              marginLeft: '10px',
              whiteSpace: 'nowrap', // Prevent text wrapping
              paddingRight: '5px', // Ensure it doesn’t touch the edge
            }}
          >
            {Math.floor(currentTime)}s / {Math.floor(duration)}s
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoComponent;